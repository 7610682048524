<div class="container">
    <div  class="d-flex justify-content-center align-items-center" style="height: 100vh;">
        <div class="error-section-container"  *ngIf="showSummaryErrors && summaryErrors">
            <div class="alert alert-danger" role="alert">
                <h5>The following fields have errors:</h5>
                <ul>
                <li *ngFor="let error of summaryErrors">{{error}}</li>
                </ul>
                Please correct these errors below and try again.
            </div>
        </div>
        <div class="sections-container">
            <div class="section-page-container" *ngIf="sectionName === 'startNewPage'">
                <p class="welcome-text"><strong>Start New Application</strong></p>
                <p class="info-text">You will need the following information to complete the application.</p>
                <ul class="no-indent">
                    <li>3 years of residence history</li>
                    <li>3-10 years of current or previous employment history, including any gaps in employment
                        <ul>
                            <li>Full addresses, phone numbers, and employment dates needed for previous employers</li>
                        </ul>
                    </li>
                    <li>3 years of traffic conviction and violation history</li>
                    <li>3 years of motor vehicle accident history</li>
                    <li>Copy of driver's license</li>
                    <li>Use your legal name as it appears on your driver's license</li>
                </ul>
                <button type="button" class="btn btn-outline-secondary" (click)="sectionName ='welcomePage'">Back</button>
                <button type="button" class="btn btn-secondary btn-position" style="margin-left: 10px;" (click)="handleStartApplication()">Start Application</button>
            </div>                
            
            <div class="section-page-container" *ngIf="sectionName === 'welcomePage'">
                <div>
                    <h1 class="text-center mb-4">Welcome, Applicant!</h1>
                    <div form-action-buttons class="action-buttons">
                        <button type="button" class="btn btn-outline-secondary" (click)="sectionName = 'resumePage'">Resume Saved Application</button>
                        <button type="button" class="btn btn-primary ms-2" (click)="sectionName = 'startNewPage'" style="margin-left: 10px;">Start New Application</button>
                    </div>    
                </div>
            </div>
            <div class="section-page-container" *ngIf="sectionName === 'resumePage'">
                <form [formGroup]="form">
                    <h2>Resume Saved Application</h2>
                    <p></p>
                    <div class="form-group col-md-5 col-12 p-0">
                        <label for="userName" class="required">{{fieldNames.userName}}</label>
                        <input type="text" id="firstName" class="form-control" formControlName="userName"
                        [ngClass]="{ 'invalid': fieldErrors['userName']?.length > 0 }">
                        <div *ngFor="let error of fieldErrors['userName']" class="errorLabel">{{error}}</div>
                    </div>       
                    <div class="form-group col-md-5 col-12 p-0">
                        <label for="pin" class="required">{{fieldNames.pin}}</label>
                        <input type="text" class="form-control" id="pin" formControlName="pin" appOnlyNumber>
                        <div *ngFor="let error of fieldErrors['pin']" class="errorLabel">{{error}}</div>
                    </div>
                    <div class="alert alert-info">
                        <span class="d-flex align-items-center">
                        <i class="fas fa-info-circle fa-2x mr-2"></i>
                        Forgot PIN? Please click "Back" and then "Start New Application".
                        </span>
                    </div>
                    <div form-action-buttons class="action-buttons">
                        <button type="button" class="btn btn-outline-secondary" (click)="clearForm(); sectionName = 'welcomePage'">Back</button>
                        <button type="button" class="btn btn-primary ms-2" (click)="handleResume()" style="margin-left: 10px;">Resume Application</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
